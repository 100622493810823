import { useEffect } from 'react';

import { AFFILIATION_DAYS } from '@core/constant';
import { useContextRouting } from '@core/context';
import { EStorageKey, setItem } from '@core/storage';
import { ERouting, EStorageType, PageSSR } from '@core/type';

import { makeServerProps } from '../../helpers/make-server-props/make-server-props';
import { useSSRPage } from '../../hooks';

export type PageReferralProps = PageSSR<{ slug: string }, unknown, unknown>;

const Referral = ({ slug }: { slug: string }) => {
  const { setRoute } = useContextRouting();

  useEffect(() => {
    if (slug) {
      setItem(EStorageType.COOKIE, EStorageKey.AFFILIATE_SLUG, slug, AFFILIATION_DAYS);
    }
    setRoute(ERouting.HOME);
  }, []);

  return <></>;
};

const PageReferralRedirect = (props: PageReferralProps) =>
  useSSRPage<{ slug: string }, unknown, { slug: string }>({
    ...props,
    ViewPage: Referral,
    propsPage: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
      ...((props as any).params as { slug: string }),
    },
  });

const getServerSideProps = makeServerProps({ keyRoute: ERouting.REFERRAL });

export { getServerSideProps };

export default PageReferralRedirect;
